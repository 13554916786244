const dataKota = () => {
    return [

        {
            title: "Sumatera Utara",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Sumut_Logo.png",
            skFile: "assets/image/struktur_kdeks/sumut.png",
            skFile2: null,
            skFile3: null,
            skFile4: null,
            skFile5: null,
            skFile6: null,
            skFile7: null,
            kode: "sumut"
        },
        {
            title: "Riau",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Riau_Logo.jpg",
            skFile: "assets/image/struktur_kdeks/riau.png",
            skFile2: null,
            skFile3: null,
            skFile4: null,
            skFile5: null,
            skFile6: null,
            skFile7: null,
            kode: "riau"
        },
        {
            title: "Kepulauan Riau",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_KEPRI_Logo.png",
            skFile: "assets/image/struktur_kdeks/kepri1.png",
            skFile2: "assets/image/struktur_kdeks/kepri2.png",
            skFile3: "assets/image/struktur_kdeks/kepri3.png",
            skFile4: null,
            skFile5: null,
            skFile6: null,
            skFile7: null,
            kode: "kepri"

        },
        {
            title: "Sumatera Barat",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Sumbar_Logo.png",
            skFile: "assets/image/struktur_kdeks/sumbar.png",
            skFile2: null,
            skFile3: null,
            skFile4: null,
            skFile5: null,
            skFile6: null,
            skFile7: null,
            kode: "sumbar"

        },
        {
            title: "Jambi",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Jambi_Logo.jpg",
            skFile: "assets/image/struktur_kdeks/jambi.png",
            skFile2: null,
            skFile3: null,
            skFile4: null,
            skFile5: null,
            skFile6: null,
            skFile7: null,
            kode: "jambi"
        },
        {
            title: "Sumatera Selatan",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Sumatera_Selatan_Logo.png",
            skFile: null,
            skFile2: null,
            skFile3: null,
            skFile4: null,
            skFile5: null,
            skFile6: null,
            skFile7: null,
            kode: "sumsel"
        },
        {
            title: "Bengkulu",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Bengkulu_Logo.jpg",
            skFile: null,
            skFile2: null,
            skFile3: null,
            skFile4: null,
            skFile5: null,
            skFile6: null,
            skFile7: null,
            kode: "bengkulu"
        },
        {
            title: "Lampung",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Lampung_Logo.jpg",
            skFile: null,
            skFile2: null,
            skFile3: null,
            skFile4: null,
            skFile5: null,
            skFile6: null,
            skFile7: null,
            kode: "lampung"
        },
        {
            title: "Bangka Belitung",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Babel_Logo.jpg",
            skFile: null,
            skFile2: null,
            skFile3: null,
            skFile4: null,
            skFile5: null,
            skFile6: null,
            skFile7: null,
            kode: "babel"
        },
        {
            title: "Gorontalo",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Gorontalo_Logo.png",
            skFile: "assets/image/struktur_kdeks/gorontalo1.png",
            skFile2: "assets/image/struktur_kdeks/gorontalo2.png",
            skFile3: null,
            skFile4: null,
            skFile5: null,
            skFile6: null,
            skFile7: null,
            kode: "gorontalo"
        },
        {
            title: "Jawa Timur",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Jawa_Timur.png",
            skFile: "assets/image/struktur_kdeks/jatim1.png",
            skFile2: "assets/image/struktur_kdeks/jatim2.png",
            skFile3: "assets/image/struktur_kdeks/jatim3.png",
            skFile4: "assets/image/struktur_kdeks/jatim4.png",
            skFile5: null,
            skFile6: null,
            skFile7: null,
            kode: "jatim"
        },
        {
            title: "Jawa tengah",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Jawa_Tengah_Logo_1.png",
            skFile: "assets/image/struktur_kdeks/jateng1.png",
            skFile2: "assets/image/struktur_kdeks/jateng2.png",
            skFile3: "assets/image/struktur_kdeks/jateng3.png",
            skFile4: "assets/image/struktur_kdeks/jateng4.png",
            skFile5: "assets/image/struktur_kdeks/jateng5.png",
            skFile6: "assets/image/struktur_kdeks/jateng6.png",
            skFile7: "assets/image/struktur_kdeks/jateng7.png",
            kode: "jateng"
        },
        {
            title: "Kalimantan Timur",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Kalimantan_Timur_Logo.jpg",
            skFile: "assets/image/struktur_kdeks/kaltim1.png",
            skFile2: "assets/image/struktur_kdeks/kaltim2.png",
            skFile3: "assets/image/struktur_kdeks/kaltim3.png",
            skFile4: null,
            skFile5: null,
            skFile6: null,
            skFile7: null,
            kode: "kaltim"
        },
        {
            title: "Kalimantan Selatan",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Kalimantan_Selatan_Logo.png",
            skFile: "assets/image/struktur_kdeks/kalsel1.png",
            skFile2: "assets/image/struktur_kdeks/kalsel2.png",
            skFile3: "assets/image/struktur_kdeks/kalsel3.png",
            skFile4: null,
            skFile5: null,
            skFile6: null,
            skFile7: null,
            kode: "kalsel"
        },
        {
            title: "Kalimantan Utara",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Kaltara_Logo.png",
            skFile: "assets/image/struktur_kdeks/kaltra1.png",
            skFile2: "assets/image/struktur_kdeks/kaltra2.png",
            skFile3: "assets/image/struktur_kdeks/kaltra3.png",
            skFile4: null,
            skFile5: null,
            skFile6: null,
            skFile7: null,
            kode: "kaltra"
        },
        {
            title: "Maluku Utara",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Maluku_Utara_Logo.jpg",
            skFile: "assets/image/struktur_kdeks/malut1.png",
            skFile2: "assets/image/struktur_kdeks/malut2.png",
            skFile3: "assets/image/struktur_kdeks/malut3.png",
            skFile4: "assets/image/struktur_kdeks/malut4.png",
            skFile5: "assets/image/struktur_kdeks/malut5.png",
            skFile6: null,
            skFile7: null,
            kode: "malut"
        },
        {
            title: "Nusa Tenggara Barat",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_NTB_Logo.jpg",
            skFile: "assets/image/struktur_kdeks/ntb1.png",
            skFile2: "assets/image/struktur_kdeks/ntb2.png",
            skFile3: null,
            skFile4: null,
            skFile5: null,
            skFile6: null,
            skFile7: null,
            kode: "ntb"
        },
        {
            title: "Sulawesi Selatan",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Sulawesi_Selatan_Logo.jpg",
            skFile: "assets/image/struktur_kdeks/sulsel1.png",
            skFile2: "assets/image/struktur_kdeks/sulsel2.png",
            skFile3: "assets/image/struktur_kdeks/sulsel3.png",
            skFile4: null,
            skFile5: null,
            skFile6: null,
            skFile7: null,
            kode: "sulsel"
        },
        {
            title: "Sulawesi Tengah",
            tanggal: "20 Juni 2024",
            foto: "assets/image/logoKdeks/KDEKS_Sulawesi_Tengah_Logo.jpg",
            skFile: "assets/image/struktur_kdeks/sulteng1.png",
            skFile2: "assets/image/struktur_kdeks/sulteng2.png",
            skFile3: null,
            skFile4: null,
            skFile5: null,
            skFile6: null,
            skFile7: null,
            kode: "sulteng"
        },

    ]
};

export default dataKota;
